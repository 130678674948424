import React from "react";
import { Fragment } from "react";
import YoutubeCore from "./components/YoutubeCore";

const Home = (props) => {
  return (
    <Fragment>
      <div className="main">
        <div className="container-fluid main-container">
          <YoutubeCore />
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
