import React from "react";
import { useState } from "react";
import { loginRequest } from "../../../components/authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ProfileData } from "./ProfileData";
import { callMsGraph } from "../../../components/api/graph";
import Button from "react-bootstrap/Button";

const UserProfile = () => {
  /**
   * Renders information about the signed-in user or a button to retrieve data about the user
   */
  const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((response) =>
            setGraphData(response)
          );
        });
    }

    return (
      <>
        <h5 className="card-title">Welcome {accounts[0].name}</h5>
        {graphData ? (
          <ProfileData graphData={graphData} />
        ) : (
          <Button variant="secondary" onClick={RequestProfileData}>
            Request Profile Information
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="container m-auto text-white user-profile">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 className="card-title">
          Please sign-in to see your profile information.
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default UserProfile;
