import { graphConfig } from "../authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const scope = "Notes.Create, Notes.Read, Notes.Read.All";

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
    scope: "offline_access " + scope,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the note
 * @param accessToken
 */
export async function getMsOneNote(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const scope = "Notes.Create, Notes.Read, Notes.Read.All";

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
    scope: "offline_access " + scope,
  };

  return fetch(graphConfig.oneNoteEndPoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the note
 * @param accessToken
 */
export async function getMsOneNoteSection(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const scope = "Notes.Create, Notes.Read, Notes.Read.All";

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
    scope: "offline_access " + scope,
  };

  return fetch(graphConfig.oneNoteSectionGetEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the note
 * @param accessToken
 */
export async function createMsOneNote(accessToken) {
  const todayDate = new Date().toISOString().slice(0, 10);
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const scope = "Notes.Create, Notes.Read, Notes.Read.All";

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    scope: "offline_access " + scope,
    body: JSON.stringify({ displayName: "Alpha Internal Note - " + todayDate }),
  };

  return fetch(graphConfig.oneNoteEndPoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the note
 * @param accessToken
 */
export async function createMsOneSection(accessToken, id) {
  const todayDate = new Date().toISOString().slice(0, 10);
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const scope = "Notes.Create, Notes.Read, Notes.Read.All";

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    scope: "offline_access " + scope,
    body: JSON.stringify({
      displayName: "Alpha Internal Section - " + todayDate,
    }),
  };

  return fetch(
    graphConfig.graphMeEndpoint + "/onenote/notebooks/" + id + "/sections",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the note
 * @param accessToken
 */
export async function createMsOnePage(accessToken, id, content) {
  const todayDate = new Date();
  const currentTime = todayDate.toLocaleTimeString();
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const scope = "Notes.Create, Notes.Read, Notes.Read.All";

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "text/html");

  const options = {
    method: "POST",
    headers: headers,
    scope: "offline_access " + scope,
    title: "Alpha Internal Page - " + currentTime,
    body: content,
  };

  return fetch(
    graphConfig.graphMeEndpoint + "/onenote/sections/" + id + "/pages",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
