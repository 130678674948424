import React from "react";
import { Fragment } from "react";
import YoutubeCapture from "./YoutubeCapture";
import SaveButton from "./SaveButton";

const ButtonContainer = (props) => {
  const player = props.player;
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-7 col-12">
          <a
            href="https://www.buymeacoffee.com/alphainternal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-green.png"
              alt="Buy Me A Coffee"
              className="donation d-none"
              id="donate"
            />
          </a>
          <YoutubeCapture player={player}/>
        </div>
        <div className="col-md-5 col-12 text-end">
          <div className="row">
            {/* <div className="col-md-6 col-12 text-end">
              <OnenoteButton />
            </div> */}
            <div className="col-md-12 col-12 text-end">
              <SaveButton />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ButtonContainer;
