import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import ReactDOMServer from "react-dom/server";

class MyEditor extends React.Component {
  handleEditorChange = (e) => {
    const content = e.target.getContent();
    this.setState({ content });
    this.props.onContentChange(content);
  };

  render() {
    return (
      <Editor
        apiKey="5y3s9uef0obatqtbc1zj2fgohbnwsuicnwjrx1ftm5wxhzcq"
        initialValue={this.props.initialValue}
        init={{
          height: "700",
          skin: "oxide-dark",
          content_css: "dark",
          plugins: "link image code",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | code",
        }}
        onChange={this.handleEditorChange}
      />
    );
  }
}

export default MyEditor;
