import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useIsAuthenticated } from "@azure/msal-react";
import SignInButton from "./components/SignInButton";
import logo from "../../images/logo.png";

const Login = ({ setLoginUser }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setLogin] = useState(false);
  const [authenticated, setauthenticated] = useState(
    localStorage.getItem(localStorage.getItem("authenticated") || false)
  );
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user, //spread operator
      [name]: value,
    });
  };

  const login = (e) => {
    e.preventDefault();
    axios
      .post(`${window.location.origin}/login`, user)
      .then((res) => {
        alert(res.data.message);
        setLoginUser(res.data.email);
        setLogin(true);
        let userDetail = {
          id: res.data.id,
          email: res.data.email,
        };
        localStorage.setItem("authenticated", true);
        localStorage.setItem("userInfo", JSON.stringify(userDetail));
        navigate("/dashboard");
        // window.location.href = "/dashboard";
      })
      .catch((error) => {
        alert(error);
      });
  };
  const register = (e) => {
    e.preventDefault();
    window.location.href = "/register";
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="container-fluid main-container">
        <div className="col-lg-4 col-12 text-center m-auto bg-brown mt-5 p-5">
          <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
            <p className="text-white fs-20 text-center text-uppercase">
              Welcome to
            </p>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center mb-5">
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={handleLogoClick}
              style={{ cursor: "pointer" }}
            />
            <p className="px-4 fs-42 text-green m-0">Alpha-Internal</p>
          </div>
          <div className="mt-8 d-flex justify-content-center">
            <form action="#" autoComplete="off" class="w-75">
              <div class="mb-2">
                <div class="input-group">
                  <input
                    type="text"
                    id="sign-in-email"
                    class="form-control rounded-start border-end-0"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    placeholder="EMAIL"
                  />
                </div>
              </div>
              <div class="mb-6">
                <div class="input-group">
                  <input
                    type="password"
                    id="sign-in-password"
                    class="form-control rounded-start border-end-0"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder="PASSWORD"
                  />
                </div>
              </div>
              <div className="flex mt-4">
                <button
                  type="submit"
                  className="form-control border-0 shadow-none text-white bg-black"
                  onClick={(e) => login(e)}
                >
                  Sign in
                </button>
                {/* display success message */}
                {/* {isLoggedIn ? (
                  <p className="text-success">You Are Logged in Successfully</p>
                ) : (
                  <p className="text-danger">You Are Not Logged in</p>
                )} */}
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <p className="ml-2 mb-0 text-white ">Don't have an account?</p>
                <span className="text-green px-3" onClick={(e) => register(e)} style={{ cursor: 'pointer' }}>
                  Sign up now
                </span>
              </div>
              <div className="flex items-center justify-center mt-4">
                <span class="or-text">OR</span>
              </div>
              <div className="flex w-full mt-4">
                <SignInButton />
              </div>
              <div className="flex items-center mb-6 mt-4">
                <div className="flex ml-auto">
                  <a href="#" className="text-white text-decoration-none">
                    Forgot Your Password?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
