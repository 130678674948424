import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../images/logo.png";

const Register = () => {
  const navigate = useNavigate();
  const [registered, setRegister] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user, //spread operator
      [name]: value,
    });
  };
  //register function
  const register = (e) => {
    e.preventDefault();
    const { name, email, password } = user;
    if (name && email && password) {
      axios.post(`${window.location.origin}/register`, user).then((res) => {
        setRegister(true);
        alert(res.data.message);
        navigate("/login");
      });
    } else {
      alert("invalid input");
    }
  };

  const login = (e) => {
    e.preventDefault();
    window.location.href = "/login";
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="container-fluid main-container">
        <div className="col-lg-4 col-12 text-center m-auto bg-brown mt-5 p-5">
          <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
            <p className="text-white fs-20 text-center text-uppercase">
              Create a new account
            </p>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center mb-5">
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={handleLogoClick}
              style={{ cursor: "pointer" }}
            />
            <p className="px-4 fs-42 text-green m-0">Alpha-Internal</p>
          </div>
          <div className="mt-8 d-flex justify-content-center">
            <form action="#" autoComplete="off" class="w-75">
              <div class="mb-2">
                <div class="input-group">
                  <input
                    type="text"
                    id="sign-in-email"
                    class="form-control rounded-start border-end-0"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                    placeholder="FullName"
                  />
                </div>
              </div>
              <div class="mb-2">
                <div class="input-group">
                  <input
                    type="password"
                    id="sign-in-password"
                    class="form-control rounded-start border-end-0"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="mb-2">
                <div class="input-group">
                  <input
                    type="password"
                    id="sign-in-password"
                    class="form-control rounded-start border-end-0"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder="password"
                  />
                </div>
              </div>

              <div className="flex mt-4">
                <button
                  type="submit"
                  className="form-control border-0 shadow-none text-white bg-black"
                  onClick={(e) => register(e)}
                >
                  Register
                </button>
                {/* display success message */}
                {registered ? (
                  <p className="text-success">
                    You Are Registered Successfully
                  </p>
                ) : (
                  <p className="text-danger"></p>
                )}
              </div>
              <div className="flex items-center justify-center mt-4">
                <p className="ml-2 text-white">
                  Already have an account ?
                  <span
                    className="text-green px-3"
                    onClick={(e) => login(e)}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Sign in
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
