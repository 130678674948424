export const handleTimeUpdate = (player, response, setCurrentSubtitleIndex) => {
  if (player && typeof player.getCurrentTime === "function") {
    const currentTime = player.getCurrentTime();
    let index = -1;
    if (response) {
      response.map((item, i) => {
        const startTime = parseFloat(item.start);
        const duration = parseFloat(item.dur);
        const endTime = startTime + duration;

        if (currentTime >= startTime && currentTime < endTime) {
          index = i;
        //   console.log(index);
        }
      });
      setCurrentSubtitleIndex(index);
    }
  }
};
