import React from "react";
import { Fragment } from "react";
import YoutubeCore from "./components/YoutubeCore";

const Video = (props) => {
  return (
    <Fragment>
      <div className="main container-fluid mt-5 mb-5 text-center">
        <div className="container-fluid">
          <YoutubeCore />
        </div>
      </div>
    </Fragment>
  );
};

export default Video;
