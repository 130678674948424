import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Sidenav } from "./components/Sidenav";
import Home from "./pages/Home";
import Video from "./pages/Home/video";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import UserProfile from "./pages/User/components/UserProfile";
import Dashboard from "./pages/User/dashboard";
import Note from "./pages/User/notes";
import NoteDetail from "./pages/User/notesDetail";
import ProtectedRoute from "./components/ProtectRoutes";
import { useState } from "react";

function App() {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen(!open);
  };


  const [isLoggedIn, setLoginUser] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  const logIn = () => {
    setLoginUser(true);
  };


  const Protected = ({ isLoggedIn, children }) => {
    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <div className="App">
      <Router>
        {/* <Header loggedInUser={loggedInUser} /> */}
        {/* <CheckPath /> */}
        <Routes>
          {/* <Route exact path="/" element={user && user._id ? <Home/>:<Login/>} /> */}
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/login"
            element={<Login setLoginUser={setLoginUser} />}
          />
          <Route exact path="/register" element={<Register />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route
              path="*"
              element={
                <div>
                  <Sidenav open={open} toggleOpen={toggleOpen} />
                  <Routes>
                    <Route exact path="/dashboard" element={<Dashboard open={open} />} />
                    <Route exact path="/UserProfile" element={<UserProfile />} />
                    <Route exact path="/note" element={<Note />} />
                    <Route exact path="/noteDetail" element={<NoteDetail />} />
                  </Routes>
                </div>
              }
            />
          </Route>
          <Route exact path="/video" element={<Video />} />
        </Routes>
      </Router>
    </div>
  );
}

// function CheckPath() {
//   const loggedInUser = localStorage.getItem("authenticated");
//   const location = useLocation();
//   const isHomePage = location.pathname === "/";
//   const isLoginPage = location.pathname === "/login";
//   const isRegisterPage = location.pathname === "/register";
//   if (loggedInUser && !isHomePage && !isLoginPage && !isRegisterPage) {
//     return <Sidenav open={true} toggleOpen={() => {}} />;
//   } else {
//     return <></>;
//   }
// }
export default App;
