import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { renderDate } from "./components/date";
import MyEditor from "./components/Editor";
import Axios from "axios";
import ReactDOMServer from "react-dom/server";
import { traverse } from "../../components/domUtils";

const NoteDetail = () => {
  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [searchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [editorContent, setEditorContent] = useState(""); // State to store the updated content

  useEffect(() => {
    Axios
      .get(`${window.location.origin}/notesDetail`, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setJsonData(JSON.parse(response.data.content));
      })
      .catch((error) => {
        console.error(error);
        // handle error response
      });
  }, []);

  useEffect(() => {
    // Whenever the editor content changes, update the state
    setEditorContent(initialValue);
  }, [initialValue]);

  const handleContentChange = (content) => {
    setEditorContent(content); // Update the editorContent state with the updated content
  };

  const saveContent = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorContent, 'text/html');  
    const elements = traverse(doc.documentElement);
    const updatedContent = JSON.stringify(elements);
    Axios.post(`${window.location.origin}/notes/update`, {
      _id: id,
      uid: user.id,
      content: updatedContent,
    })
      .then((res) => {
        alert(res.data.message);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const detail = jsonData.map((item, index) => {
    // Check if item.text exists before rendering
    if (item.tag === "p") {
      return (
        <p className="text-white" key={item.text}>
          {item.text}
        </p>
      );
    } else if (item.tag === "img") {
      return <img width="50%" src={item.src} key={item.src} alt={item.alt} />;
    }
  });

  const initialValue = ReactDOMServer.renderToString(detail);

  return (
    <div className="container detail-container  p-5">
      <a onClick={goBack} className="text-white">
        <FontAwesomeIcon icon={faChevronLeft} /> Note List
      </a>
      <div className="my-3">
        <h5 className="text-white">Note ID: {id}</h5>
        <h5 className="text-white">
          Created: {renderDate(data.date_created)}{" "}
        </h5>
        <div className="content-container my-5">
          <MyEditor
            initialValue={initialValue}
            onContentChange={handleContentChange}
          />
        </div>
      </div>
      <div className="text-end my-3">
        <button className="save-button" onClick={saveContent}>
          SAVE
        </button>
      </div>
    </div>
  );
};

export default NoteDetail;
