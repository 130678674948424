import React from 'react';
import HomeIcon from "@mui/icons-material/Home";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import SettingsIcon from "@mui/icons-material/Settings";
export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Profile",
    link: "/dashboard",
  },
  {
    id: 1,
    icon: <TravelExploreIcon />,
    text: "My Note",
    link: "/note",
  },
//   {
//     id: 2,
//     icon: <BarChartIcon />,
//     text: "Statistics",
//     link: "statistics",
//   },
  {
    id: 3,
    icon: <SettingsIcon />,
    text: "Settings",
    link: "settings",
  },
];
