import React from "react";
import { Fragment } from "react";
import YouTube from "react-youtube";
import { handleTimeUpdate } from "./TimeUpdate";

class YoutubeEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
    };
  }

  onReady = (event) => {
    this.setState({
      player: event.target,
    });
    if (this.props.onPlayerReady) {
      this.props.onPlayerReady(event.target);
    }
  };

  componentDidMount() {
    this.intervalId = setInterval(() => {
      const player = this.state.player;
      const response = this.props.data.apiResponse;
      const setCurrentSubtitleIndex = this.props.setCurrentSubtitleIndex;
      if (player && typeof player.getCurrentTime === "function") {
        handleTimeUpdate(player, response, setCurrentSubtitleIndex);
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const videoId = this.props.data.videoId;
    const { title } = this.props.data;
    const opts = {
      title: { title },
      height: "480",
      width: "853",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        // controls: 1, // enable player controls
      },
    };

    return (
      <Fragment>
        <div className="video-responsive d-none" id="video-container">
          <YouTube
            id="youtubeEmbed"
            videoId={videoId}
            opts={opts}
            onReady={this.onReady}
            // onPause={() => {
            //   console.log("I have been paused");
            // }}
          />
        </div>
      </Fragment>
    );
  }
}

export default YoutubeEmbed;
