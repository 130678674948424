import React from "react";
import { useState, useEffect, Fragment } from "react";
import Axios from "axios";

const YoutubeCapture = (props) => {
  const player = props.player;
  const [contentList, setContentList] = useState([{ content: "" }]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    // Get the elements with the "my-class" class name
    const elements = document.getElementsByClassName("removeNode");

    // Loop through the elements and add the event listener to each one
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", handleServiceRemove);
    }

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", handleServiceRemove);
      }
    };
  }, [contentList]);

  const captureElement = () => {
    let captureBtn = document.getElementById("cit");
    let content = document.getElementById("youtube-storage-output");
    let date = Date.now();
    let icon =
      '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"></path></svg>';
    const fileName = new Date().getTime();
    const videoData = player.getVideoData();
    const videoCurrentTime = player.getCurrentTime();
    const videoId = videoData.video_id;

    captureBtn.innerHTML = "Processing...";
    captureBtn.disabled = true;
    Axios.get(`${window.location.origin}/screenshot`, {
      params: { videoCurrentTime, videoId, fileName },
    })
      .then((response) => {
        var img = new Image();
        //img.src = `data:image/png;base64,${response.data}`;
        Axios.get(`${window.location.origin}/getImage`, {
          params: { fileName },
        }).then((res) => {
          console.log(res.data);
          img.src = res.data;
          img.alt = videoCurrentTime;
          img.onload = function () {
            let html =
              '<li id="image_' +
              date +
              '"><div class="subItem imageItem d-flex align-items-center"><div class="col-12 subtitleBox d-flex justify-content-between"><div class="imageBox" id="imageBox_' +
              date +
              '"></div><div style="display: flex;align-items: center;"><a href="#" onChange={handleNameChange} class="removeNode" id="remove_' +
              date +
              '">' +
              icon +
              "</a></div></li>";
            content.innerHTML += html;
            let subtitleBox = document.getElementById("imageBox_" + date);
            subtitleBox.append(img);
            setContentList([...contentList, { content: html }]);
          };
        });
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong!!!");
      })
      .finally(() => {
        captureBtn.innerHTML = "Capture";
        captureBtn.disabled = false;
    });
    return;
  };

  const handleServiceRemove = (e) => {
    e.preventDefault();
    setClicked(true);
    const list = [...contentList];
    const parent = e.target.closest("li");
    parent.remove();
    setContentList(list);
  };

  return (
    <Fragment>
      <button
        id="cit"
        onClick={() => captureElement()}
        className="btn btn-secondary m-5 button capture-btn d-none"
      >
        Capture
      </button>
    </Fragment>
  );
};

export default YoutubeCapture;
