const traverse = (node) => {
    const elements = [];
  
    const traverseNode = (node) => {
        const element = {};
        if (node.nodeName === "P") {
          element.tag = "p";
          let text = "";
          let href = "";
          node.childNodes.forEach((childNode) => {
            if (childNode.nodeName === "A") {
              href = childNode.getAttribute("href") || "";
              traverse(childNode);
            } else if (childNode.nodeName === "#text") {
              text += childNode.textContent;
            }
          });
          element.text = text.trim();
          element.href = href;
        } else if (node.nodeName === "IMG") {
          element.tag = "img";
          element.src = node.getAttribute("src") || "";
          element.alt = node.getAttribute("alt") || "";
        }
        if (element.tag) {
          elements.push(element);
        }
        if (node.childNodes) {
            node.childNodes.forEach(traverseNode); // <-- Correct the recursive call here
          }
      };
  
    traverseNode(node);
    return elements;
  };
  
  export { traverse };