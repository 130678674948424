import React from "react";
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { navData } from "../lib/navData";
import { useState } from "react";
import styles from "./sidebar.module.css";

export const Sidenav = ({ open, toggleOpen }) => {
  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      <button
        className={styles.menuBtn}
        onClick={() => {
          toggleOpen();
        }}
      >
        {open ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </button>
      <div className={styles.sidenavlink}>
        {navData.map((item) => {
          return (
            <NavLink key={item.id} className={styles.sideitem} to={item.link}>
              {item.icon}
              <span className={styles.linkText}>{item.text}</span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
