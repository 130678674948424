import React, { useEffect, useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { renderDate } from "./components/date";

import "../../css/note.css";

import axios from "axios";

const Note = ({ props }) => {
  // const loggedInUser = localStorage.getItem("authenticated");
  const userDetails = JSON.parse(localStorage.getItem("userInfo"));
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [data, setData] = useState([]);
  const getItemsToDisplay = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data.slice(indexOfFirstItem, indexOfLastItem);
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handelRecordDelete = (e) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this note?"
    );
    if (confirmDelete) {
      const id = e.target.getAttribute("data-id");
      axios
        .delete(`${window.location.origin}/notes/delete`, {
          params: { id: id },
        })
        .then((response) => {
          console.log(response.data);
          fetchData();
        })
        .catch((error) => {
          console.error(error);
          // handle error response
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const uid = userDetails.id; // Get the ID parameter from the props object
    axios
      .get(`${window.location.origin}/notes`, {
        params: {
          uid: uid,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
        // handle error response
      });
  };

  const tableRow = getItemsToDisplay().map((item, index) => (
    <tr key={item._id} id={item._id}>
      <td>{index + 1}</td>
      <td>{renderDate(item.date_created)}</td>
      <td>
        {JSON.parse(item.content).map((text) => {
          // Check if text.text exists before rendering
          if (text.tag === "p") {
            return <p key={text.text}>{text.text}</p>;
          } else if (text.tag === "img") {
            return <img src={text.src} key={text.src} alt={text.alt} />;
          }
        })}
      </td>
      <td>
        <div className="d-flex">
          <NavLink
            tag={Link}
            to={`/noteDetail?${encodeURIComponent("id")}=${encodeURIComponent(
              item._id
            )}`}
          >
            <FontAwesomeIcon icon={faEye} />
          </NavLink>
          <NavLink data-id={item._id} onClick={handelRecordDelete}>
            <FontAwesomeIcon icon={faTrash} style={{ pointerEvents: "none" }} />
          </NavLink>
        </div>
      </td>
    </tr>
  ));

  return (
    <div className="container note-container p-5">
      <Table
        striped
        bordered
        hover
        size="sm"
        variant="dark"
        className="text-white"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Created Date</th>
            <th>Content</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableRow}</tbody>
      </Table>
      <Pagination>
        {pageNumbers.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default Note;
